import React from 'react';
import styles from './index.module.css';

const RecommendationCard = ({ name, title, profileImageUrl, recommendation }) => {
    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <div className={styles.profile} style={{ backgroundImage: `url(${profileImageUrl})` }}/>
                <div className={styles.details}>
                    <div className={styles.name}>
                        {name}
                    </div>
                    <div className={styles.title}>
                        {title}
                    </div>
                </div>
            </div>
            <div className={styles.recommendation}>
                {recommendation}
            </div>
        </div>
    );
};

export default RecommendationCard;
