import React, { useState } from "react";
import styles from "./index.module.css";
import Spacer from "./spacer";
import ProjectCard from "./project-card";
import RecommendationCard from "./recommendation-card";

import AutoGenSlides from "./assets/media/documents/autogen-slides.pdf";
import IntroToAgents from "./assets/media/documents/intro-to-agents.pdf";
import IntroToLLMS from "./assets/media/documents/intro-to-llms.pdf";
import CDI from "./assets/media/documents/ai-conversational-data-intelligence.pdf";
import ArduinoSlides from './assets/media/documents/arduino-slides.pdf'
import SXSWSlides from './assets/media/documents/sxsw-slides.pdf'
import GitHubRepoCard from "./github-card";
import BondAILogo from './assets/open-source/bondai-logo.png'
import ArduinoLogo from './assets/open-source/arduino.png'
import PusherLogo from './assets/open-source/pusher.png'
import AlexaLogo from './assets/open-source/alexa.png'
import DollyLogo from './assets/open-source/dolly.png'
import AlpacaLogo from './assets/open-source/alpaca.png'
import NLACTImg from './assets/projects/nl-act.gif'
import MobileNetV3Img from './assets/projects/mobilenetv3.png'
import SpuqCartographyImg from './assets/projects/dataset_map_intrasample_epoch_5.png'
import AgentImg from './assets/projects/agent.png'
import JasonImg from './assets/profiles/jason.jpeg'
// import EmilyImg from './assets/profiles/emily.jpeg'
import EricImg from './assets/profiles/eric.jpeg'
import SariahImg from './assets/profiles/sariah.jpeg'
import MattImg from './assets/profiles/matt-swanson.jpeg'
import MasonImg from './assets/profiles/mason.jpeg'
import PrathapImg from './assets/profiles/prathap.jpeg'


// Blog API
// https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@krohling

const Home = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    const scrollToSection = (sectionId) => {
        setMenuOpen(false); // Close the menu
    
        setTimeout(() => {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }, 300);

        // setTimeout(() => {
        //     const section = document.getElementById(sectionId);
        //     if (section) {
        //         const topOffset = section.getBoundingClientRect().top + window.pageYOffset;
        //         window.scrollTo({
        //             top: topOffset,
        //             behavior: 'smooth'
        //         });
        //     }
        // }, 300);
    };

    return <div>
            <div className={`${styles.hamburgerIcon} ${styles.mobile} ${isMenuOpen ? styles.showMenu : ''}`} onClick={toggleMenu}>
                <span/>
                <span/>
                <span/>
            </div>

            <div className={`${styles.mobileMenu} ${isMenuOpen ? styles.showMenu : ''} ${styles.mobile}`}>
                <button className={styles.closeButton} onClick={toggleMenu}>
                    <span>&times;</span>
                </button>
                <button onClick={() => scrollToSection('homeSection')}>Home</button>
                <a href="https://medium.com/@krohling" target="_blank" rel="noopener noreferrer">Blog</a>
                <button onClick={() => scrollToSection('skillsSection')}>Skills</button>
                <button onClick={() => scrollToSection('recentProjectsSection')}>AI Projects</button>
                <button onClick={() => scrollToSection('openSourceSection')}>Open Source</button>
                <button onClick={() => scrollToSection('recentWorkSection')}>Recent Work</button>
                <button onClick={() => scrollToSection('educationSection')}>Education</button>
                <button onClick={() => scrollToSection('recommendationsSection')}>Recommendations</button>
                <button onClick={() => scrollToSection('mediaSection')}>Media</button>
            </div>

            <div className={`${styles.desktopMenu} ${styles.desktop}`}>
                <button onClick={() => scrollToSection('homeSection')}>Home</button>
                <a href="https://medium.com/@krohling" target="_blank" rel="noopener noreferrer">Blog</a>
                <button onClick={() => scrollToSection('skillsSection')}>Skills</button>
                <button onClick={() => scrollToSection('recentProjectsSection')}>AI Projects</button>
                <button onClick={() => scrollToSection('openSourceSection')}>Open Source</button>
                <button onClick={() => scrollToSection('recentWorkSection')}>Recent Work</button>
                <button onClick={() => scrollToSection('educationSection')}>Education</button>
                <button onClick={() => scrollToSection('recommendationsSection')}>Recommendations</button>
                <button onClick={() => scrollToSection('mediaSection')}>Media</button>
            </div>

            <div id="homeSection" className={styles.hero}>
                <div className={styles.headline}>
                    <div className={styles.profileImage}/>
                    <div className={styles.headlineText}>
                        <h1>Kevin Rohling</h1>
                        <h2>I love building things people love (with AI)</h2>
                        <div className={styles.socialIcons}>
                            <a href="https://www.linkedin.com/in/krohling" target="_blank" rel="noopener noreferrer">
                                <div className={`${styles.socialIcon} ${styles.linkedin}`}/>
                            </a>
                            <a href="https://github.com/krohling" target="_blank" rel="noopener noreferrer">
                                <div className={`${styles.socialIcon} ${styles.github}`}/>
                            </a>
                            <a href="mailto:kevin@kevinrohling.com" target="_blank" rel="noopener noreferrer">
                                <div className={`${styles.socialIcon} ${styles.email}`}/>
                            </a>
                        </div>
                        <a className={styles.resumeLink} href="./kevin-rohling-resume.pdf" target="_blank" rel="noopener noreferrer">
                            View My Resume
                        </a>
                    </div>
                </div>
            </div>
            <div id="skillsSection" className={styles.section}>
                <div className={styles.sectionTitle}>Skills</div>
                <div className={styles.skillsGrid}>
                    <div className={`${styles.skillsItem} ${styles.title} ${styles.ai}`}>
                        Machine Learning & AI
                    </div>
                    <div className={`${styles.skillsItem} ${styles.pytorch}`}/>
                    <div className={`${styles.skillsItem} ${styles.computerVision}`}>
                        <div className={styles.label}>
                            Computer Vision
                        </div>
                    </div>
                    <div className={`${styles.skillsItem} ${styles.nlp}`}>
                        <div className={styles.label}>
                            Natural Language Processing
                        </div>
                    </div>
                    <div className={`${styles.skillsItem} ${styles.python}`}/>
                    <div className={`${styles.skillsItem} ${styles.react}`}/>
                    <div className={`${styles.skillsItem} ${styles.nodejs}`}/>
                    <div className={`${styles.skillsItem} ${styles.title} ${styles.software}`}>
                        Software Engineering
                    </div>
                    <div className={`${styles.skillsItem} ${styles.title} ${styles.other}`}>
                        Certifications
                    </div>
                    <div className={`${styles.skillsItem} ${styles.aws}`}/>
                    <div className={`${styles.skillsItem} ${styles.scrum}`}/>
                    <div className={`${styles.skillsItem} ${styles.crossfit}`}/>
                </div>
            </div>
            <div id="recentProjectsSection" className={styles.section}>
                <div className={styles.sectionTitle}>Recent AI Projects</div>
                <div className={styles.sectionItemList}>
                    <ProjectCard
                        name="NL-ACT: Natural Language Instructions for Robotic Manipulation"
                        githubUrl="https://github.com/krohling/nl-act"
                        paperUrl="./nl-act.pdf"
                        technologies="PyTorch, Python, all-mpnet-base-v2, Action Chunking Transformers, Mujoco, Docker"
                        description="I extended the Action Chunking Transformer (ACT) architecture to integrate natural language embeddings, successfully enabling multi-task learning and conditioning task execution on unseen instructions. This work achieved a success rate of 89.3% across three robotic manipulation tasks: grasping, stacking, and object transfer."
                        imageUrl={NLACTImg}
                        />

                    <ProjectCard
                        name="Food Classification for Consumer Smart Oven Device"
                        technologies="PyTorch, Python, Custom PyTorch Build, Convolutional Neural Networks, scikit-learn, MobileNetv3, Dataset Augmentation, Dataset Preparation, K-Fold Validation, Weights & Biases, Labelbox, AWS S3, EC2"
                        description="As part of my role as Head of AI at Presence Product Group, I trained a MobileNetv3 CNN on a custom dataset of 20,000 images across 31 classes for a client developing a consumer smart oven device. The model achieved real-time inference accuracy of over 95% on an embedded device, demonstrating my ability to train and optimize models for deployment on resource-constrained devices."
                        imageUrl={MobileNetV3Img}
                        />
                    
                    <ProjectCard
                        name="Investigating Data-Efficient Generalization"
                        githubUrl="https://github.com/krohling/spuq-cartography"
                        paperUrl="./spuq-cartography.pdf"
                        technologies="PyTorch, Python, scikit-learn, Language Model Finetuning, Dataset Cartography, SPUQ, Data Augmentation, Weights & Biases, SageMaker, Docker, Runpod.io"
                        description="In this project, I explored methods to improve model generalization using Dataset Cartography, augmentation, and Sampling with Perturbation Uncertainty Quantification (SPUQ) techniques. The model was trained using the ELECTRA-small discriminator architecture on the SQuAD dataset and evaluated on both in-domain (SQuAD) and out-of-domain (AdversarialQA) datasets. By focusing on high-variability examples and augmenting data with paraphrased and adversarial examples, the project achieved a 27.9 F1 score on the out-of-domain dataset—outperforming the baseline random subset (25.5) and approaching the full dataset performance (29.4). This work demonstrates that training on carefully selected subsets enhances model robustness to domain shifts, particularly in adversarial contexts."
                        imageUrl={SpuqCartographyImg}
                        />
                    
                    <ProjectCard
                        name="AI Agent for Clinical Trial Datasets"
                        technologies="Python, Multi-Agent Systems, Chain Of Thought (CoT), Few Shot Prompting, LLM Integration, LLM Code Generation, LLM Tool Usage, Docker, JavaScript, Node.js, R, SQL"
                        description="At Presence Product Group, I led the development of a multi-agent system for analyzing complex clinical trial datasets (SDTM & ADaM), leveraging GPT-4 for user requirements gathering, iterative code generation (R & SQL), and analysis summarization. This system enabled non-technical users to interact with the data and generate insights. When shown to the client’s customers, 9 out of 10 wanted to adopt the product in their organization."
                        imageUrl={AgentImg}
                        />
                </div>
            </div>
            <div id="openSourceSection" className={styles.section}>
                <div className={styles.sectionTitle}>Open Source</div>
                <div className={styles.sectionItemList}>
                    <GitHubRepoCard 
                        name="BondAI"
                        logo={BondAILogo}
                        repoUrl="https://api.github.com/repos/krohling/bondai"
                        repoHtmlUrl="https://github.com/krohling/bondai"
                        description="BondAI is an open-source framework for developing AI Agent Systems. BondAI handles tool execution, memory/context management, semantic search and includes a powerful set of out of the box tools & integrations."
                        />
                    <GitHubRepoCard 
                        name="Finetuned Flan UL2 Dolly"
                        logo={DollyLogo}
                        repoUrl="https://api.github.com/repos/ConiferLabsWA/flan-ul2-dolly"
                        repoHtmlUrl="https://github.com/ConiferLabsWA/flan-ul2-dolly"
                        description="This repository contains code for using the Dolly 15K dataset released by Databricks to fine tune the Flan-UL2 model for instruction following."
                        />
                    <GitHubRepoCard 
                        name="Finetuned Flan UL2 Alpaca"
                        logo={AlpacaLogo}
                        repoUrl="https://api.github.com/repos/ConiferLabsWA/flan-ul2-alpaca"
                        repoHtmlUrl="https://github.com/ConiferLabsWA/flan-ul2-alpaca"
                        description="This repository contains code for using the Stanford Alpaca synthetic dataset to fine tune the Flan-UL2 model for instruction following."
                        />
                    <GitHubRepoCard 
                        name="Arduino Websocket Client"
                        logo={ArduinoLogo}
                        repoUrl="https://api.github.com/repos/krohling/ArduinoWebsocketClient"
                        repoHtmlUrl="https://github.com/krohling/ArduinoWebsocketClient"
                        description="Websockets are a simple and lightweight way to send and receive messages from web browsers. This project was developed to extend this capability to embedded devices (Arduinos)."
                        />
                    <GitHubRepoCard 
                        name="Arduino Pusher Client"
                        logo={PusherLogo}
                        repoUrl="https://api.github.com/repos/krohling/ArduinoPusherClient"
                        repoHtmlUrl="https://github.com/krohling/ArduinoPusherClient"
                        description="Pusher is a hosted Push Notification service that uses Websockets for relaying messages back and forth between clients. This library extends this functionality to Arduino devices."
                        />
                    <GitHubRepoCard 
                        name="Chat GPT Alexa Skill"
                        logo={AlexaLogo}
                        repoUrl="https://api.github.com/repos/ConiferLabsWA/molly-gpt-alexa-skill"
                        repoHtmlUrl="https://github.com/ConiferLabsWA/molly-gpt-alexa-skill"
                        description="This repository contains code for an Alexa skill that uses OpenAI models to conversationally respond to user input."
                        />
                </div>
            </div>
            <div id="recentWorkSection" className={styles.section}>
                <div className={styles.sectionTitle}>Recent Work</div>
                <div className={styles.sectionItemList}>
                    <Spacer />
                    <div className={`${styles.workItem} ${styles.presence}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                Presence Product Group
                            </div>
                            <div className={styles.description}>
                                Head of Artificial Intelligence<br/>
                                <i>2023 - 2024</i>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                    <div className={`${styles.workItem} ${styles.presence}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                Presence Product Group
                            </div>
                            <div className={styles.description}>
                                Sr. Engineering Lead<br/>
                                <i>2020 - 2023</i>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                    <div className={`${styles.workItem} ${styles.testai}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                Test.AI
                            </div>
                            <div className={styles.description}>
                                VP Engineering<br/>
                                <i>2019 - 2020</i>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                    <div className={`${styles.workItem} ${styles.booknook}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                BookNook Learning
                            </div>
                            <div className={styles.description}>
                                Founding Engineer/CTO<br/>
                                <i>2016 - 2019</i>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                    <div className={`${styles.workItem} ${styles.emberlight}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                Emberlight
                            </div>
                            <div className={styles.description}>
                                Founding Engineer/VP Engineering<br/>
                                <i>2014 - 2015</i>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                    <div className={`${styles.workItem} ${styles.cisimple}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                cisimple
                            </div>
                            <div className={styles.description}>
                                Founding Engineer/CEO<br/>
                                <i>2012 - 2014</i>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                    {/* 
                    <div className={`${styles.workItem} ${styles.breezy}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                Breezy
                            </div>
                            <div className={styles.description}>
                                Founding CTO<br/>
                                <i>2011 - 2012</i>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                    <div className={`${styles.workItem} ${styles.vertigo}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                Verito Software
                            </div>
                            <div className={styles.description}>
                                Sr Software Engineer<br/>
                                <i>2009 - 2011</i>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                    <div className={`${styles.workItem} ${styles.disney}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                The Walt Disney Company
                            </div>
                            <div className={styles.description}>
                                Software Engineer<br/>
                                <i>2009 - 2011</i>
                            </div>
                        </div>
                    </div>
                    <Spacer /> */}
                </div>
            </div>
            <div id="educationSection" className={styles.section}>
                <div className={styles.sectionTitle}>Education</div>
                <div className={styles.sectionItemList}>
                    <Spacer />
                    <div className={`${styles.workItem} ${styles.uttexas}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                University of Texas at Austin
                            </div>
                            <div className={styles.description}>
                                MS Artificial Intelligence<br/>
                                <i>In Progress</i>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                    <div className={`${styles.workItem} ${styles.ucf}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                University of Central Florida
                            </div>
                            <div className={styles.description}>
                                BS Computer Science<br/>
                                <i>2008</i>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                    <div className={`${styles.workItem} ${styles.scrum}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                Scrum.org
                            </div>
                            <div className={styles.description}>
                                Professional Scrum Master I<br/>
                                <i>2023</i>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                    <div className={`${styles.workItem} ${styles.crossfit}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                CrossFit, LLC
                            </div>
                            <div className={styles.description}>
                                CrossFit Level 1<br/>
                                <i>2021</i>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                    <div className={`${styles.workItem} ${styles.usf}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                University of San Francisco
                            </div>
                            <div className={styles.description}>
                                Deep Learning - Part I<br/>
                                <i>2018</i>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                    <div className={`${styles.workItem} ${styles.edx}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                EdX
                            </div>
                            <div className={styles.description}>
                                Embedded Systems<br/>
                                <i>2014</i>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                    <div className={`${styles.workItem} ${styles.edx}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                EdX
                            </div>
                            <div className={styles.description}>
                                Quantum Mechanics and Quantum Computation<br/>
                                <i>2013</i>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                </div>
            </div>
            <div id="recommendationsSection" className={styles.section}>
                <div className={styles.sectionTitle}>Recommendations</div>
                <div className={styles.sectionItemList}>
                    <RecommendationCard 
                        name="Jason Monberg"
                        title="CEO, Presence Product Group"
                        profileImageUrl={JasonImg}
                        recommendation="Kevin and I worked with each other over 4 years on a range of AI enabled products, from traditional predictive analytics to pushing the envelope in the rapidly changing GenAI space. Kevin is exceptionally skilled, committed, and thoughtful in his work. He was consistently able to deliver complex technology solutions within committed timeframes and budgets. He is also a great leader and mentor. As the head of our AI practice, he not only led the delivery of innovative AI projects, he also promoted the use and understanding of AI technology, directly mentoring team members. He will be missed!"
                        />
                    
                    <RecommendationCard 
                        name="Matthew Kael Swanson"
                        title="Managing Partner, Silicon Valley Software Group"
                        profileImageUrl={MattImg}
                        recommendation="As a technical architect at SVSG, Kevin has repeatedly exceeded expectations with both the engineers he has managed and the clients he has serviced. The nature of the work that Kevin has performed requires deep technical knowledge as well as solid communication skills, both of which Kevin has excelled at. "
                        />
                    
                    <RecommendationCard 
                        name="Mason Blake"
                        title="Serial Entrepreneur; Co-founder Ratio, UpCouncil; Ex-LinkedIn"
                        profileImageUrl={MasonImg}
                        recommendation="Kevin is an extremely well-rounded thinker with a deep set of skills that span software development, building teams, starting companies, scaling technology and much more. 
 
He's not only a fantastic guy and a joy to work with, but knows how to get the job done, is timely and enjoys adding value where it's most important. Could not recommend Kevin enough!"
                        />
                    
                    <RecommendationCard 
                        name="Prathap G. Dendi"
                        title="Growth at Adobe"
                        profileImageUrl={PrathapImg}
                        recommendation="I highly recommend Kevin. Kevin is a textbook example of a thoroughbred silicon valley entrepreneur. A hands-on tech founder with valuable CEO experience incubating and growing bleeding edge tech startups. He is a 'natural' leader with broad cross-functional skills and growth hacking abilities. He is a rare combination of all-in-one entrepreneurial package and a down to earth, ethical personality. It has been a pleasure working with him."
                        />
                    
                    {/* <RecommendationCard 
                        name="Emily Giddings"
                        title="Marketing Strategist, Presence Product Group"
                        profileImageUrl={EmilyImg}
                        recommendation="It was an absolute privilege to work with Kevin Rohling during his time as our Head of AI at Presence. Kevin has this rare ability to dive deep into complex technical challenges while also zooming out to envision what's possible. We first connected over a mutual interest in Leonard Susskind and Max Tegmark, and our conversations often veered into thought-provoking discussions on AI, theoretical physics, and future innovations. These exchanges led to some of our most creative marketing collaborations.

What sets Kevin apart is his deep technical expertise paired with an adventurous and creative spirit. In an industry that's often bogged down by jargon, Kevin stays grounded and refreshingly genuine. Despite my non-engineering background, he always welcomed me into technical discussions, curious about my creative input—even when it was sometimes off the mark.

Early on, we co-created a company-wide presentation exploring the future of development, marketing, and humanity in an AI future. We also collaborated on blog posts and case studies and even launched a LinkedIn Live series where we invited AI experts to dive into groundbreaking ideas and technical possibilities. All the while, Kevin was instrumental in guiding our most complex and foundational AI/ML projects. 

His decision to pursue a Master's in AI is no surprise; his relentless curiosity and drive to master artificial intelligence will make him an invaluable asset wherever he goes. Kevin is not just a colleague; he’s a friend in expansive thinking and a leader in exploring new frontiers.🪐"
                        /> */}
                    
                    <RecommendationCard 
                        name="Eric Müller"
                        title="VP of Engineering, Presence Product Group"
                        profileImageUrl={EricImg}
                        recommendation="Kevin is one of the most passionate technologists I have ever met. He puts his whole heart into every task he takes on, making sure it's done to the best of his and his team's ability. Kevin also is not afraid to jump in an learn something new, rapidly becoming an expert. That was most obvious with our AI efforts. He saw the potential before it was the cool thing to do and devoured pretty much anything and everything. He lead some amazing gen AI, machine learning, and image recognition projects. Having Kevin on the project resulted in some very happy clients. 

One other thing that stands our for me is Kevin the person: he is honest, direct, calm, and thoughtful. Overally, Kevin is a great person to have a team."
                        />
                    
                    <RecommendationCard 
                        name="Sariah Sizemore"
                        title="Head of QA, Presence Product Group"
                        profileImageUrl={SariahImg}
                        recommendation="I had the pleasure of working with Kevin on a number of projects when acting as Head of Quality and Presence Product Group. One of the many things I love about Kevin is his open mind, creative perspective, and enthusiasm for building technology. He's not afraid to explore the uncharted territory of AI, and I've seen him not only become an enthusiast, but an expert in a short amount of time. This is a testament to his intelligence and willingness to jump in, design, and build things quickly - even when using new building blocks. 

In addition, Kevin is an amazing collaborator and cheerleader for all fo the folks he works with. It's fun to work with Kevin and that makes him a rare gem. I highly recommend Kevin to lead your company into harnessing the future of AI technology and beyond!"
                        />
                </div>
            </div>
            <div id="mediaSection" className={styles.section}>
                <div className={styles.sectionTitle}>Media</div>
                <div className={styles.sectionItemList}>
                    <a href="https://blog.dragosbogdan.net/p/ai-in-2023-and-beyond-interview-w?utm_campaign=post&utm_medium=web" target="_blank" rel="noopener noreferrer">
                        <div className={`${styles.mediaItem} ${styles.video} ${styles.aiinterview}`}>
                            <div className={styles.cover}>
                                Dragos Bogdan Interview
                            </div>
                        </div>
                    </a>
                    <a href={IntroToLLMS} target="_blank" rel="noopener noreferrer">
                        <div className={`${styles.mediaItem} ${styles.slideDeck} ${styles.llms}`}/>
                    </a>
                    <a href="https://www.youtube.com/watch?v=AiMIDUbeJZk" target="_blank" rel="noopener noreferrer">
                        <div className={`${styles.mediaItem} ${styles.video} ${styles.llmsvideo}`}>
                            <div className={styles.cover}>
                                Introduction to LLMs
                            </div>
                        </div>
                    </a>
                    <a href={AutoGenSlides} target="_blank" rel="noopener noreferrer">
                        <div className={`${styles.mediaItem} ${styles.slideDeck} ${styles.autoGen}`}/>
                    </a>
                    <a href={IntroToAgents} target="_blank" rel="noopener noreferrer">
                        <div className={`${styles.mediaItem} ${styles.slideDeck} ${styles.agents}`}/>
                    </a>
                    <a href={CDI} target="_blank" rel="noopener noreferrer">
                        <div className={`${styles.mediaItem} ${styles.document} ${styles.cdi}`}/>
                    </a>
                    <a href={ArduinoSlides} target="_blank" rel="noopener noreferrer">
                        <div className={`${styles.mediaItem} ${styles.slideDeck} ${styles.arduino}`}/>
                    </a>
                    <a href={SXSWSlides} target="_blank" rel="noopener noreferrer">
                        <div className={`${styles.mediaItem} ${styles.slideDeck} ${styles.sxsw}`}/>
                    </a>
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.socialIcons}>
                    <a href="https://www.linkedin.com/in/krohling" target="_blank" rel="noopener noreferrer">
                        <div className={`${styles.socialIcon} ${styles.linkedin}`}/>
                    </a>
                    <a href="https://github.com/krohling" target="_blank" rel="noopener noreferrer">
                        <div className={`${styles.socialIcon} ${styles.github}`}/>
                    </a>
                    <a href="mailto:kevin@kevinrohling.com" target="_blank" rel="noopener noreferrer">
                        <div className={`${styles.socialIcon} ${styles.email}`}/>
                    </a>
                </div>
            </div>
        </div>
}

export default Home;