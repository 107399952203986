import React from 'react';
import styles from './index.module.css';

const ProjectCard = ({ name, githubUrl, paperUrl, technologies, description, imageUrl }) => {
    return (
        <div className={`${styles.root}`}>
            <div className={styles.details}>
                <div className={styles.name}>
                    {name}
                </div>
                <div className={styles.technologies}>
                    <i>{technologies}</i>
                </div>
                {
                    (githubUrl || paperUrl) && (<div className={styles.links}>
                            {
                                githubUrl && (<a href={githubUrl} target="_blank" rel="noopener noreferrer" className={styles.link}>Github</a>)
                            }
                            {
                                paperUrl && (<a href={paperUrl} target="_blank" rel="noopener noreferrer" className={styles.link}>View Paper</a>)
                            }
                        </div>
                    )
                }
                <div className={styles.description}>
                    {description}
                </div>
                {
                    imageUrl && (
                        <img src={imageUrl} alt={name} className={styles.image} />
                    )
                }
            </div>
        </div>
    );
};

export default ProjectCard;
